export interface ClientConfig {
  client_id: string;
  response_type: string;
  redirect_uri: string;
  post_logout_redirect_uri: string;
}

export const client: ClientConfig = {
  client_id: "rp-verifier",
  redirect_uri: window.location.origin + "/callback",
  post_logout_redirect_uri: window.location.origin,
  response_type: "id_token token",
};
