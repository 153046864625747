import { FunctionComponent, PropsWithChildren } from "react";
import { Logo } from "./Logo";

interface Props extends PropsWithChildren {
  logoSrc: string;
}

const Navigation: FunctionComponent<Props> = ({ logoSrc, children }) => {
  return (
    <div>
      <div className="LogoWrapper">
        <Logo src={logoSrc} />
      </div>
      <nav className="Navigation">
        <ul className="NavigationList">{children}</ul>
      </nav>
    </div>
  );
};

export default Navigation;
