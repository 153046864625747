import { FunctionComponent } from "react";

import "../App.css";

import { useOpenID } from "../openid/provider";

type Props = {
  scope: string;
  providers: string[];
  login_hint: string;
};

export const Authentication: FunctionComponent<Props> = ({
  scope,
  providers,
  login_hint,
}) => {
  const openID = useOpenID();
  if (openID.id_token) {
    const { id_token, signoutUrl } = openID;
    return (
      <a href={`${signoutUrl(id_token)}`} data-qa="logout">
        Sign out
      </a>
    );
  } else {
    const { authorizationUrl } = openID;
    return (
      <a
        href={authorizationUrl({
          login_hint: login_hint !== "" ? login_hint : undefined,
          scope,
          claims:
            providers.length > 0
              ? JSON.stringify({ id_token: { amr: { values: providers } } })
              : undefined,
        })}
        data-qa="login"
      >
        Logga in
      </a>
    );
  }
};
