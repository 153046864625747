import { useEffect, useState } from "react";
import { useOpenID } from "../openid/provider";

export const UserInfo = () => {
  const { access_token, getUserInfo } = useOpenID();
  const [state, setState] = useState<{
    loading: boolean;
    error: string | null;
    userinfo: { roles: string[] } | null;
  }>({
    loading: false,
    error: null,
    userinfo: null,
  });
  useEffect(() => {
    access_token &&
      getUserInfo(access_token).then((userinfo) =>
        setState({ loading: false, error: null, userinfo }),
      );
  }, [access_token, getUserInfo]);

  if (state.loading) {
    return <pre>loading userinfo...</pre>;
  }

  if (state.userinfo == null) {
    return null;
  }

  return (
    <div>
      <h3>Userinfo</h3>
      <pre>{state.error}</pre>
      <pre data-qa="userinfo--payload">
        {JSON.stringify(state.userinfo, null, 2)}
      </pre>
    </div>
  );
};
