import { FunctionComponent, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  href?: string;
}

const NavigationLink: FunctionComponent<Props> = ({ children }) => {
  return <li className="NavigationLink">{children}</li>;
};

export default NavigationLink;
