import { FunctionComponent } from "react";

type Props = { src: string };

export const Logo: FunctionComponent<Props> = ({ src }) => {
  return (
    <div className="Logo">
      <img alt="hsb-logo" src={src}></img>
    </div>
  );
};
