import { useState } from "react";
import "./App.css";

import * as config from "./config";

import { OpenIDProvider } from "./openid/provider";
import { Authentication } from "./components/Authentication";
import { IdToken } from "./components/IdToken";
import { AccessToken } from "./components/AccessToken";
import { SwitchRoles } from "./components/SwitchRoles";
import { UserInfo } from "./components/UserInfo";

import Navigation from "./components/Navigation";
import NavigationLink from "./components/NavigationLink";

const allScopes = [
  "openid",
  "profile",
  "roles",
  "accounts",
  "personal_identity_number10",
  "entitlements",
  "provider",
  "customers",
  "customer_id",
  "region_id",
  "offline_access",
];

const providers = [
  "eid",
  "ad",
  "idsrv-dev",
  "idsrv-qa",
  "idsrv-test",
  "idsrv-prod",
];

type Props = {
  oidc_issuer: string;
};

const App = (props: Props) => {
  const [scope, setScope] = useState(allScopes);
  const [selectedProviders, setProviders] = useState(providers);
  const [login_hint, setLogin_hint] = useState("");
  return (
    <div className="App">
      <OpenIDProvider
        clientConfig={config.client}
        issuerUrl={props.oidc_issuer}
      >
        <Navigation logoSrc="/hsb-logo-ny2.png">
          <NavigationLink>
            <SwitchRoles
              login_hint={login_hint}
              scope={scope.join(" ")}
              providers={selectedProviders}
            />
          </NavigationLink>
          <NavigationLink>
            <Authentication
              login_hint={login_hint}
              scope={scope.join(" ")}
              providers={selectedProviders}
            />
          </NavigationLink>
        </Navigation>

        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div>
            <p>Scopes: </p>
            <select
              name="scopes"
              id="scopes"
              style={{ width: "200px" }}
              onChange={(evt) => {
                const event = { ...evt };
                const selectedOptions = [];
                for (let i = 0; i < event.target.selectedOptions.length; i++) {
                  selectedOptions.push(event.target.selectedOptions[i].value);
                }
                setScope(selectedOptions);
              }}
              value={scope}
              multiple
            >
              {allScopes.map((scope) => (
                <option key={scope} value={scope}>
                  {scope}
                </option>
              ))}
            </select>
          </div>

          <div>
            <p>Providers: </p>
            <select
              name="providers"
              id="providers"
              style={{ width: "200px" }}
              onChange={(evt) => {
                const event = { ...evt };
                const selectedOptions = [];
                for (let i = 0; i < event.target.selectedOptions.length; i++) {
                  selectedOptions.push(event.target.selectedOptions[i].value);
                }
                setProviders(selectedOptions);
              }}
              value={selectedProviders}
              multiple
            >
              {providers.map((provider) => (
                <option key={provider} value={provider}>
                  {provider}
                </option>
              ))}
            </select>
            <br />
            <button
              onClick={(_) => {
                setProviders([]);
              }}
            >
              Töm providers
            </button>
          </div>

          <div>
            <p>login_hint</p>
            <input
              value={login_hint}
              onChange={(e) => {
                const event = { ...e };
                setLogin_hint(event.target.value);
              }}
            />
          </div>
        </div>

        <hr />

        <section>
          <IdToken />
        </section>
        <section>
          <AccessToken />
        </section>
        <section>
          <UserInfo />
        </section>
      </OpenIDProvider>
    </div>
  );
};

export default App;
