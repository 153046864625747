import { FunctionComponent, useEffect, useState } from "react";

import { useOpenID } from "../openid/provider";
import * as Customer from "../models/Customer";
import * as UserInfo from "../models/UserInfo";
import * as Role from "../models/Role";

const filterByRoles = (roleType: Role.RoleType, roles: string[]) => {
  const availableCustomerNumbers = roles
    .filter((role) => role.endsWith(roleType))
    .map((role) => {
      const [_regionId, customerNumber, ..._] = role.split(":");
      return customerNumber;
    })
    .filter((c) => c !== "")
    .map((c) => parseInt(c, 10));

  return (customer: Customer.t) =>
    availableCustomerNumbers.includes(customer.customerNumber);
};

const make_claims = ({
  customer_id,
  region_id,
  role_type,
  providers,
}: {
  customer_id?: number | null;
  region_id?: number | null;
  role_type: Role.RoleType | null;
  providers: string[];
}) => {
  const claims = Object.assign(
    { amr: { values: providers } },
    customer_id != null
      ? {
          customer_id: {
            required: true,
            value: customer_id.toString(),
          },
        }
      : {},
    region_id != null
      ? {
          region_id: {
            required: true,
            value: region_id.toString(),
          },
        }
      : {},
    role_type != null
      ? {
          role_type: {
            required: true,
            value: role_type,
          },
        }
      : {},
  );

  return JSON.stringify({ id_token: claims, access_token: claims });
};

type Props = {
  scope: string;
  providers: string[];
  login_hint: string;
};

export const SwitchRoles: FunctionComponent<Props> = ({
  scope,
  providers,
  login_hint,
}) => {
  const { access_token, decoded_id_token, getUserInfo, authorizationUrl } =
    useOpenID();
  const [customer, setCustomer] = useState<Customer.t | null>(null);
  const [currentRoleType, setRoleType] = useState<Role.RoleType>(
    decoded_id_token?.payload?.role_type || Role.RoleType.member,
  );
  const [state, setState] = useState<{
    loading: boolean;
    error: string | null;
    userinfo: UserInfo.t | null;
  }>({
    loading: false,
    error: null,
    userinfo: null,
  });
  useEffect(() => {
    access_token &&
      getUserInfo(access_token).then((userinfo: UserInfo.t) => {
        setState({ loading: false, error: null, userinfo });
        setCustomer((prev) => {
          const currentCustomerNumber =
            decoded_id_token?.payload.customer_id != null
              ? parseInt(decoded_id_token.payload.customer_id, 10)
              : null;
          const currentRegionId =
            decoded_id_token?.payload.region_id != null
              ? parseInt(decoded_id_token.payload.region_id, 10)
              : null;

          return (
            userinfo?.customers?.find(
              (c) =>
                c.customerNumber === currentCustomerNumber &&
                c.regionId === currentRegionId,
            ) || prev
          );
        });
      });
  }, [access_token, getUserInfo, decoded_id_token]);

  if (state.loading || state.userinfo == null) {
    return null;
  }

  const customers =
    state.userinfo.customers == null ? [] : state.userinfo.customers;

  const userRoles: Role.RoleType[] =
    state.userinfo.roles == null
      ? [Role.RoleType.member]
      : state.userinfo.roles
          .map((role) => {
            const [_regionId, _customerId, _roleId, isBoardMember, isEmployee] =
              role.split(":");
            return `${isBoardMember}:${isEmployee}` as Role.RoleType;
          })
          .filter((roleType, idx, arr) => arr.indexOf(roleType) === idx);

  return (
    <>
      <select
        data-qa="switch-role--select-roletype"
        value={currentRoleType}
        onChange={(e) => {
          // Viktigt att denna plockas ut eftersom setState är async och events återanvänds av react
          const roleValue = e.target.value as Role.RoleType;
          setRoleType((_) => roleValue);
          setCustomer((_) => null);
        }}
      >
        {userRoles.map((rt: Role.RoleType) => (
          <option key={rt} value={rt}>
            {Role.roleTypeNames[rt]}
          </option>
        ))}
      </select>

      <select
        data-qa="switch-roles--select_customer"
        className="Select-role"
        value={customer?.customerId}
        onChange={(e) => {
          const customerId = e.target.value;
          setCustomer(
            (_) =>
              customers.find(
                (c) => c.customerId === parseInt(customerId, 10),
              ) || null,
          );
        }}
      >
        {customers
          .filter(filterByRoles(currentRoleType, state.userinfo.roles))
          .map((customer) => (
            <option key={customer.customerId} value={customer.customerId}>
              {customer.niceName}
            </option>
          ))}
      </select>
      <a
        data-qa="switch-roles--link"
        href={authorizationUrl({
          login_hint: login_hint !== "" ? login_hint : undefined,
          scope,
          claims: make_claims({
            providers,
            customer_id: customer?.customerNumber,
            region_id: customer?.regionId,
            role_type: currentRoleType,
          }),
        })}
      >
        Switch roles
      </a>
    </>
  );
};
