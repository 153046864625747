import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

// TODO: Move somewhere else
type ApplicationConfig = {
  oidc_issuer: string;
};

fetch("./application.json")
  .then((res) => res.json())
  .then((applicationConfig: ApplicationConfig) => {
    const root = ReactDOM.createRoot(document.getElementById("root")!);
    root.render(<App oidc_issuer={applicationConfig.oidc_issuer} />);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
