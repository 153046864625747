import { request, nonce, serialize } from "../utils";
import { ClientConfig } from "../config";
import * as UserInfo from "../models/UserInfo";

export interface DiscoveryDocument {
  authorization_endpoint: string;
  end_session_endpoint: string;
  userinfo_endpoint: string;
}

export interface authorizationParams {
  scope: string;
  regionId?: string;
  customerId?: string;
  claims?: string;
  id_token_hint?: string;
  prompt?: "none";
  login_hint?: string;
}

export interface OpenIdClient {
  getUserInfo: (token: string) => Promise<UserInfo.t>;
  authorizationUrl: (params: authorizationParams) => string;
  signoutUrl: (id_token: string) => string;
}

export async function createOpenIDClient(
  issuerUrl: string,
  client: ClientConfig,
): Promise<OpenIdClient> {
  const { client_id, redirect_uri, response_type, post_logout_redirect_uri } =
    client;

  // Fetch discovery document
  function discover(): Promise<OpenIdClient> {
    return request(`${issuerUrl}/.well-known/openid-configuration`)
      .then((issuer) => {
        function authorizationUrl(params: authorizationParams) {
          const query = {
            ...params,
            client_id,
            response_type,
            redirect_uri,
            nonce: nonce(),
          };
          return `${issuer.authorization_endpoint}?${serialize(query)}`;
        }

        function signoutUrl(id_token: string) {
          const query = { id_token_hint: id_token, post_logout_redirect_uri };
          return `${issuer.end_session_endpoint}?${serialize(query)}`;
        }

        async function getUserInfo(token: string) {
          return request(issuer.userinfo_endpoint, {
            headers: {
              "content-type": "application/json",
              authorization: "Bearer " + token,
            },
          }).catch((err) => {
            console.error(err);
            // Clear session from storage and refresh
            localStorage.setItem("access_token", "");
            localStorage.setItem("id_token", "");
            window.location.replace("/");
          });
        }

        return { getUserInfo, authorizationUrl, signoutUrl };
      })
      .catch((err) => {
        console.error(err);
        new Error("Unable to fetch discovery document");
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            discover().then(resolve).catch(reject);
          }, 2000);
        });
      });
  }

  return discover();
}
