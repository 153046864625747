export const nonce = () =>
  `${Math.random().toString(36).substring(2)}${Math.random()
    .toString(36)
    .substring(2)}`;

export async function request(path: string, params?: {}) {
  return fetch(path, params).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
}

export const serialize = (obj: {
  [key: string]: string | number | boolean | undefined;
}) =>
  Object.keys(obj)
    .filter((key) => obj[key] != null)
    .map(
      (key) =>
        encodeURIComponent(key) +
        "=" +
        encodeURIComponent(obj[key] as string | number | boolean),
    )
    .join("&");

export const urlDecode = (str: string) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
};
