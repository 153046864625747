import React, { createContext, useContext, useEffect, useState } from "react";

import { createOpenIDClient, OpenIdClient } from "./client";
import { ClientConfig } from "../config";
import * as Role from "../models/Role";
import * as Utils from "../utils";

import Navigation from "../components/Navigation";
import Spinner from "../components/Spinner";

interface Props {
  issuerUrl: string;
  clientConfig: ClientConfig;
  children: React.ReactNode;
}

interface DecodedToken {
  headers: {};
  payload: {
    customer_id: string;
    region_id: string;
    role_type: Role.RoleType;
  };
}

interface Tokens {
  id_token: string | null;
  access_token: string | null;
  decoded_id_token: DecodedToken | null;
  decoded_access_token: DecodedToken | null;
}

// We're going to show loading if this is not true
const Context = createContext<OpenIdClient & Tokens>(
  {} as OpenIdClient & Tokens,
);

const decoded_of_token = (token: string) => {
  return token.split(".").reduce((acc, curr, idx) => {
    if (idx >= 2) return acc;
    return {
      ...acc,
      [idx === 0 ? "header" : "payload"]: JSON.parse(Utils.urlDecode(curr)),
    };
  }, {}) as DecodedToken;
};

export const OpenIDProvider = ({
  issuerUrl,
  clientConfig,
  children,
}: Props) => {
  const [client, setClient] = useState<OpenIdClient | null>(null);
  const [tokens, setTokens] = useState(() => {
    const id_token = localStorage.getItem("id_token");
    const access_token = localStorage.getItem("access_token");
    const decoded_id_token =
      id_token != null && id_token !== "" ? decoded_of_token(id_token) : null;
    const decoded_access_token =
      access_token != null && access_token !== ""
        ? decoded_of_token(access_token)
        : null;
    return {
      access_token: localStorage.getItem("access_token"),
      id_token,
      decoded_id_token,
      decoded_access_token,
    };
  });

  // Setup OpenID client
  useEffect(() => {
    createOpenIDClient(issuerUrl, clientConfig).then(setClient);
  }, [issuerUrl, clientConfig]);

  // Sign-in redirect
  useEffect(() => {
    const { access_token, id_token } = Object.fromEntries(
      (
        new URLSearchParams(window.location.hash.split("#")[1]) as any
      ).entries(),
    );
    // Set tokens
    if (access_token) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("id_token", id_token);
      setTokens({
        access_token,
        id_token,
        decoded_id_token: decoded_of_token(id_token),
        decoded_access_token: decoded_of_token(access_token),
      });
      window.history.pushState(null, "", "/app");
    }
  }, []);

  // Not initialized
  if (client == null || !client.authorizationUrl) {
    return (
      <div>
        <Navigation logoSrc="/hsb-logo-ny2.png" />
        <Spinner />
      </div>
    );
  }

  const context = { ...client, ...tokens };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useOpenID = () => useContext(Context);
