export enum RoleType {
  member = "0:0",
  boardMember = "1:0",
  employee = "0:1",
  unknown = "1:1",
}

export const roleTypeNames = {
  [RoleType.member]: "Member",
  [RoleType.boardMember]: "Boardmember",
  [RoleType.employee]: "Employee",
  [RoleType.unknown]: "unknown",
};
